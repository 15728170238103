<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div id="buttongroup" style="display: flex; justify-content: flex-end">
            <div class="field is-grouped">
              <div class="control">
                <button :disabled="!$hasPermissions(clientSession, ['REPORT_TYPES'], 2)" @click="handleReportTypesSubmit" type="submit" class="button is-accent has-text-white">Save</button>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Report Type Properties</span>
              </div>
              <div class="block">
                <form @submit.prevent="handleReportTypesSubmit()">
                  <div>
                    <div class="field">
                      <label class="label is-size-7">
                        Report Type Name
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input :class="{ 'input': true, 'is-danger': this.errors.name }" type="text" name="name" v-model="input.name" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Report Type Code
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                          :class="{ 'input': true, 'is-danger': this.errors.code }"
                          type="text"
                          name="typecode"
                          v-model="input.code"
                          :disabled="!isCreateMode"
                        />
                      </div>
                      <small style="color: #eaae5e!important;">Report Type Code cannot be modified after saving the Report Type.</small>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                       Category
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <select :class="{ 'input select': true, 'is-danger': errors.category !== undefined }" name="" id="" v-model="input.category">
                          <option value="">Select Category</option>
                          <option v-for="category in categories" :key="category.id" :value="category.code">{{ category.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
            <div class="column">
              <!-- Reserved for future -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
let pageName = 'Report Type Details'

export default {
  components: {
    Loading
  },
  props: {
    model: {
      default: () => {
        return {
          code: '',
          name: '',
          category: ''
        }
      }
    }
  },
  data () {
    return {
      input: {
        code: '',
        name: '',
        category: ''
      },
      errors: {},
      inputDefault: {},
      isLoading: false,
      categories: [],
      pageheading: pageName
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let code = this.$route.params.code
      return !code || code <= 0
    }
  },
  created () {
    // if external direct link, need to load the model state
    if (!this.isCreateMode) {
      let route = `/report-types/${this.$route.params.code}`
      this.loadModel(route, true)
    } else {
      this.setupReportTypesForm(this.model)
    }

    this.getCategories()
  },
  // async beforeRouteEnter (to, from, next) {
  //   const reportType = JSON.parse(to.params.model)
  //   next(vm => {
  //     vm.input.id = report.bio
  //     vm.input.name = report.name
  //     vm.input.typeCode = report.typeCode
  //     vm.input.typeCodeDescription = report.typeCodeDescription
  //     vm.input.institutionId = report.institutionId
  //     vm.input.institutionName = report.institutionName
  //     vm.input.yearOverride = report.yearOverride
  //     vm.input.extension = report.extension
  //     vm.input.uploadDate = report.uploadDate
  //     vm.input.fileSize = report.fileSize
  //   })
  // },
  methods: {
    async getCategories () {
      this.categories = {
        user: {
          code: 'user',
          name: 'User Document'
        },
        vendor: {
          code: 'vendor',
          name: 'Vendor Document'
        },
        client: {
          code: 'client',
          name: 'Client Document'
        },
        carrier: {
          code: 'carrier',
          name: 'Carrier Document'
        }
      }
    },
    async handleReportTypesSubmit () {
      let code = this.inputDefault.code
      let route = `/report-types/${code}`

      if (this.input.code === '') {
        this.errors.code = 'Report Type Code is required'
      } else {
        delete this.errors.code
      }

      if (!this.input.category) {
        this.errors.category = 'Report category is required'
      } else {
        delete this.errors.category
      }

      if (!this.input.name) {
        this.errors.name = 'Report Type Name is required'
      } else {
        delete this.errors.name
      }

      if (Object.keys(this.errors).length > 0) {
        this.$forceUpdate()
        this.errorToast('The form contains errors. Please correct them and try again.')
        return
      }

      await this.handleSubmit(route, -1, true, true, `The Report Type Code '${this.input.code}' already exists. Please enter a unique code.`)
    },
    setupReportTypesForm (model) {
      if (this.input.code) delete this.input.code
      this.setupForm(model)
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
